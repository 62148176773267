//     							                          //
//  Program: booktourform.jsx                             //
//  Application: KINI HTML                                //
//  Option: Book Tour Form                                //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import TextField from '@mui/material/TextField';
import CmsApi from "../../services/CmsApi";
import ApplicantsService from "../../services/ApplicantsService";
import { Spinner } from "react-bootstrap";
import Moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ReCAPTCHA from "react-google-recaptcha"
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import MetaSEO from "../../../MetaSEO";
import SpinnerLoader from "../common/SpinnerLoader";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const recaptchaRef = React.createRef();
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            personName: [],
            date: new Date('2014-08-18T21:11:54'),
            selectedDate: new Date(),
            type: false,
            value: undefined,
            inputValue: undefined,
            input: {},
            errors: {
                email: "",
                // phone_number:"",
                first_name: "",
                // recaptcha:""

            },
            headerToken: localStorage.getItem("headerToken") ? localStorage.getItem("headerToken") : "",
            userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {},
            first_name: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")).first_name : "",
            last_name: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")).last_name : "",
            email: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")).email : "",
            phone_number: "",
            applicant_photo: "",
            price_range: "",
            desired_move_in_date: null,
            comment: "",
            status_id: true,
            helperText: "",
            bedroom: [],
            bedroomArray: [],
            priceRangeArray: [],
            loader: false,
            isValidRecatcha: false,
            errorCount: 0,
            property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
            // meta tags start
            title: global.level === 2 ? localStorage.getItem("brand_name") : (global.level === 3 ? localStorage.getItem("property_name") : "OHAI"),
            favicon: global.level === 2 ? localStorage.getItem("brand_logo_url") : (global.level === 3 ? localStorage.getItem("property_logo_url") : "OHAI"),
            keywords: global.keywords,
            description: global.description,
            url: window.location.href,
            image: global.level === 2 ? localStorage.getItem("brand_logo_url") : (global.level === 3 ? localStorage.getItem("property_logo_url") : ""),
            fb_app_id: "",
            // meta tags end
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setInputValue = this.setInputValue.bind(this);
        // this.handleChangebedroom = this.handleChangebedroom.bind(this);
        // this.handleChangePriceRange = this.handleChangePriceRange.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.saveOrUpdateApplicants = this.saveOrUpdateApplicants.bind(this);
        this.getcmsCommonData = this.getcmsCommonData.bind(this);
        this.getCmsPropertyData = this.getCmsPropertyData.bind(this);
        this.getcmsSettingData = this.getcmsSettingData.bind(this);
        this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
        this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);
    }

    componentDidMount() {
        localStorage.setItem("propertySlug", this.state.property_slug);
        // this.getCmsPropertyData();
        // this.getcmsCommonData();
        // setTimeout(this.getcmsSettingData(), 100);
        //this.getcmsSettingData();
        // /setTimeout( () =>  console.clear(),5000);
    }

    onChangeRecaptcha = (value) => {
        // console.log("onChangeRecaptcha", value);
        if (value !== "") {
            var errors = this.state.errors;
            errors.recaptcha = ""
            this.setState({ isValidRecatcha: true, errors: errors });
        }
    }

    onErrorRecaptcha = (value) => {
        // console.log("onErrorRecaptcha", value);
        // var errors = this.state.errors;
        // errors.recaptcha = "Verification expired. Check the checkbox again."
        this.setState({ isValidRecatcha: false });
        recaptchaRef.current.reset();
    }

    setPrice = (e) => {
        var price = document.getElementById("price-selection");
        price !== null ? price.classList.remove("show") : "";
        // console.log(price);
        // unit.click();
        // price.classList.remove("show");
    }

    handleDateChangeout(date, name) {
        let input = this.state.input;
        let a = new Date(date);
        input.desired_move_in_date = Moment(new Date(a)).format("YYYY-MM-DD");
        this.setState({ desired_move_in_date: a, input: input });
    }

    getCmsPropertyData = async (queryString = "") => {
        // this.setState({ showSpinner: true, loading: true });
        var resData = {};
        let res = await CmsApi.getCmsPropertyData(this.state.property_slug);
        if (global.successStatus.includes(res.status)) {
            (resData.logo = res.data.property_logo_thumb_url
                ? res.data.property_logo_thumb_url
                : KINIlogo),
                (resData.phone_number = res.data.property_phone_number
                    ? res.data.property_phone_number
                    : "(786)-465-6661");
            resData.property_logo_thumb_url = res.data.property_logo_thumb_url
                ? res.data.property_logo_thumb_url
                : "";
            resData.property_name = res.data.property_name
                ? res.data.property_name
                : "";
            localStorage.setItem("property_name", res.data.property_name);
            localStorage.setItem("phone_number", res.data.property_phone_number);
            localStorage.setItem("property_logo_url", res.data.property_logo_thumb_url);
        }
        this.setState(resData);
        // this.setState({
        //   showSpinner: false,
        //   loading: false,
        // });
    };

    getcmsCommonData = async (queryString = "") => {
        this.setState({ showSpinner: true, loading: true });
        var section_header_data = [];
        let res = await CmsApi.getcmsCommonData(this.state.property_slug);
        if (global.successStatus.includes(res.status)) {
            section_header_data =
                res.data[0] && res.data[0].section_data ? res.data[0].section_data : [];
        }
        await this.setState({
            showSpinner: false,
            loading: false,
            section_header_data: section_header_data,
        });
    };

    getcmsSettingData = async (queryString = "") => {
        //to get bedroom and price range array
        // this.setState({ showSpinner: true, loading: true });

        var bedroomArray = [];
        var priceRangeArray = [];
        var cacheKeyName = global.theme + "_VIRTUAL_TOUR_DROPDOWN_DATA_" + this.state.property_slug;
        if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
            var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
            this.setState(data);
        }
        let res = await CmsApi.getSettingData(this.state.property_slug);
        if (global.successStatus.includes(res.status)) {
            bedroomArray = res.data ? res.data.bedroomArray : []
            priceRangeArray = res.data ? res.data.priceRangeArray : []
        }
        global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify({
            bedroomArray: bedroomArray,
            priceRangeArray: priceRangeArray
        }));
        this.setState({
            bedroomArray: bedroomArray,
            priceRangeArray: priceRangeArray
        });
    };

    handleChange = (event) => {
        this.setState({ personName: event.target.value });
    };

    handleDateChange(event) {
        this.setState({ selectedDate: event.target.value });
    }

    onFocus() {
        // alert("kldg");
        this.setState({ type: true });
    }

    onBlur() {
        this.setState({ type: false });
    }


    setValue(event) {
        // console.log(event);
    }

    setInputValue(event) {
        // console.log(event);
    }
    // PhoneInput(props) {
    //     return (
    //       <InputMask
    //         mask='(+1) 999 999 9999'
    //         value={props.value}
    //         onChange={props.onChange}>
    //       </InputMask>
    //     );
    //   }

    saveOrUpdateApplicants = async (e) => {
        e.preventDefault();
        var captcha = false;
        if (this.state.errors.email === "" && this.state.email !== "" && this.state.errors.first_name === "" && this.state.first_name !== "" && this.state.errors.last_name === "") {
            this.setState({ loader: true });
            // this.setState({ showSpinner: true, isSubmit: true });
            var origin = window.location.origin ? window.location.origin :"";
            var pathname = window.location.pathname ? window.location.pathname:"";
            var finalPath = origin + pathname; 
            let inputData = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                page_from: finalPath,
                notes: this.state.notes,
                sort_order: this.state.sort_order,
                status_id: this.state.status_id === true ? 1 : 2,
            };

            let res = await ApplicantsService.createContact(inputData);
            if (global.successStatus.includes(res.status)) {
                this.setState({
                    showAlertModal: true,
                    successContact: true,
                    alertModalType: "success",
                    alertModalTitle: "Success",
                    alertModalMessage: res.message ? res.message : "Success",
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    desired_move_in_date: null,
                    bedroom_number: "",
                    price_range_slug: "",
                    notes: "",
                    status_id: true,
                });
                if (this.state.errorCount >= 3) {
                    recaptchaRef.current.reset();
                }
                // var redirectBtn = document.getElementById("virtual-tour-button");
                // redirectBtn.click();
                setTimeout(() => {
                    window.location.href = "/";
                }, 10000);
                // this.props.history.push(global.themeUrl + this.state.property_slug + "/virtual-tour-confirmation");
            } else {
                if (res.data.result.errorDetail) {
                    let errors = {};
                    res.data.errorCode = "Validation Failed";
                    res.data.result.errorDetail.forEach((item) => {
                        errors[item.errorField] = item.errorMessage[0];
                        res.data.errorMessage = "";
                    });
                    this.setState({ errors: errors });
                }
                // this.setState({
                //   showAlertModal: true,
                //   alertModalType: "error",
                //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
                //   alertModalMessage: res.data.message ? res.data.message : "Error!",
                // });
            }
            this.setState({ loader: false });

            // this.setState({ showSpinner: false, isSubmit: false });
            setTimeout(
                () => this.setState({ showAlertModal: false }),
                global.alert_close_time
            );
        } else {
            //show required errors
            var errors = this.state.errors;
            var errorCount = this.state.errorCount;
            // if(!this.state.phone_number){
            //     document.getElementById("phone_number").focus();
            //     errors.phone_number = "Phone number is required."
            // }
            if (!this.state.first_name) {
                document.getElementById("first_name").focus();
                errors.first_name = "First name is required."
            }
            if (!this.state.email) {
                document.getElementById("email").focus();
                errors.email = "Email is required."
            }
            // if(errorCount >= 3){
            // if(!this.state.isValidRecatcha){
            //     errors.recaptcha = "Recaptcha requires verification."
            // }
            //}
            this.setState({ errors: errors, errorCount: errorCount + 1 });
        }
    };

    validateEmail = () => {
        var result = global.OnKeyPressEvent.validateEmail(this.state.email);
        if (!result) {
            let errors = this.state.errors;
            errors.email = "Please enter email."
            this.setState({ errors: errors });
        } else {
            let errors = this.state.errors;
            errors.email = ""
            this.setState({ errors: errors });
        }
    }

    changeHandler = (event) => {
        let input = this.state.input;
        let value = event.target.value;
        const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
        if (event.target.name === "first_name") {
            var errors = this.state.errors;
            if (event.target.value.length >= 2) {
                if (regex.test(event.target.value) === false) {
                    errors.first_name = "Please enter valid first name.";
                    this.setState({ errors: errors });
                  } else {
                    errors.first_name = "";
                    this.setState({ errors: errors });
                  }
            }
            else {
                errors.first_name = "";
                this.setState({ errors: errors });
              }
        }
        if (event.target.name === "last_name") {
            var errors = this.state.errors;
            if (event.target.value.length >= 2) {
                if (regex.test(event.target.value) === false) {
                    errors.last_name = "Please enter valid last name.";
                    this.setState({ errors: errors });
                  } else {
                    errors.last_name = "";
                    this.setState({ errors: errors });
                  }
            }
            else {
                errors.last_name = "";
                this.setState({ errors: errors });
              }
        }
        if (event.target.name === "phone_number") {
            var errors = this.state.errors;
            if (event.target.value.length > 8) {
                errors.phone_number = "";
                this.setState({ errors: errors });
            }
            if (event.target.value.length > 15 || event.target.value.length < 8) {
                var errors = this.state.errors;
                errors.phone_number = "The phone number must be a valid phone number"
                this.setState({ errors: errors });
            }
        }
        if (event.target.name === "email") {
            var errors = this.state.errors;
            if (event.target.value.length > 7) {
                const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (regex.test(event.target.value) === false) {
                    errors.email = "The email must be a valid email address.";
                    this.setState({ errors: errors });
                } else {
                    errors.email = "";
                    this.setState({ errors: errors });
                }
            }
        }

        input[event.target.name] = value;
        this.setState({ [event.target.name]: value, input: input });
    };

    // handleChangebedroom(event) {
    //     // console.log("chgjehgch ===>  ", event.target.name, event.target.value)
    //     let input = this.state.input;
    //     input[event.target.name] = event.target.value;
    //     this.setState({ bedroom: event.target.value, input: input });
    // }

    // handleChangePriceRange(event) {
    //     let input = this.state.input;
    //     input[event.target.name] = event.target.value;
    //     this.setState({ price_range: event.target.value, input: input });
    // }


    render() {
        //         const [phone, setPhone] = useState('');
        //   const handleInput = ({ target: { value } }) => setPhone(value);
        // const [value, setValue] = React.useState(options[0]);
        // const [inputValue, setInputValue] = React.useState('');

        return (
            <main className="oh-template Brandmainpage">
                {/* Meta tag start */}


                <MetaSEO
                    title={this.state.title}
                    favicon={this.state.favicon}
                    keywords={this.state.keywords}
                    description={this.state.description}
                    url={this.state.url}
                    image={this.state.image}
                    fb_app_id={this.state.fb_app_id}
                    noIndex="true"
                    author={"OHAI"}
                />

                {/* Header */}
                <CommonHeader propertySlug={this.state.property_slug} />
                {/* middle */}
                {
                    this.state.successContact ?
                        <section className="middle">
                            <div className="container-fluid p-0">
                                <section className="ConfirmationBooking pd-t-100 pd-b-100">
                                    <div className="container pd-b-60">
                                        <div className="content">

                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} className="mg-b-20" />
                                            {/* <h4 className="mg-b-15"> We’d love to hear from you! And don’t forget — Reservations are currently open.Thank you! </h4> */}
                                            <h4 className="mg-b-15"> Thank you! Your message has been delivered. ✨</h4>
                                            {/* <p>We have also sent you the link on your email. </p> */}
                                            <div className="d-flex justify-content-center flex-column align-items-center mg-t-30">

                                                {/* <a href=""><button>Book Now</button> </a> */}
                                            </div>


                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                        :
                        <section className="middle">
                            <div className="container-fluid p-0">
                                <section className="reservationBook contact_sec">
                                    <div className="container ">
                                        <div className="content">
                                            <div className="row mobView ">
                                                <div className=" col-md-6">
                                                    <div className="requestSection contact_form_box">
                                                        <div className="row">
                                                            <div className="col-12 pd-l-15">
                                                                <h1> Contact</h1>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                                                <div className="countryphone">
                                                                    <TextField id="first_name" name="first_name" value={this.state.first_name} autoFocus={true} onChange={(e) => this.changeHandler(e)} label="First name" variant="outlined" fullWidth required
                                                                        error={this.state.errors.first_name ? true : false}
                                                                        helperText={this.state.errors.first_name ? this.state.errors.first_name : ""} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                                                <div className="countryphone">
                                                                    <TextField id="last_name" name="last_name" autoFocus={false} value={this.state.last_name} onChange={(e) => this.changeHandler(e)} label="Last name" variant="outlined" fullWidth
                                                                     error={this.state.errors.last_name ? true : false}
                                                                     helperText={this.state.errors.last_name ? this.state.errors.last_name : ""} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                                            <div className="countryphone">
                                                            {/* onBlur={this.validateEmail}  */}
                                                                <TextField type="email" name="email" value={this.state.email} autoFocus={false} onChange={(e) => this.changeHandler(e)} id="email" label="Email" variant="outlined" fullWidth required className=" textfield"
                                                                    error={this.state.errors.email ? true : false}
                                                                    helperText={this.state.errors.email ? this.state.errors.email : ""} />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-6 pd-l-15 pd-r-15 mg-b-30">
                                                    <InputMask
                                                        type="text"
                                                        id="phone_number"
                                                        label="Phone Number"
                                                        name="phone_number"
                                                        placeholder="Phone Number *"
                                                        formatChars={{
                                                            "9": "[0-9]",
                                                            a: "[A-Za-z]",
                                                            "*": "[A-Za-z0-9]"
                                                            }}
                                                            mask="99-999-999-9999"
                                                            maskChar=" "
                                                        onKeyPress={global.OnKeyPressEvent.phoneValidation}
                                                        value={this.state.phone_number}
                                                        onChange={this.changeHandler}
                                                        variant="outlined"
                                                        className="MuiOutlinedInput-input borderInput textfield w-100 react-datepicker-ignore-onclickoutside"
                                                        // inputProps={{ maxLength: 15 }}
                                                        />
                                                        {
                                                            this.state.errors.phone_number ?
                                                            <p className="error-message">{this.state.errors.phone_number}</p>
                                                            : ""
                                                        }
                                                    </div> */}
                                                            {/* <div>
                                                        <InputMask
                                                                formatChars={{
                                                                "9": "[0-9]",
                                                                a: "[A-Za-z]",
                                                                "*": "[A-Za-z0-9]"
                                                                }}
                                                                mask="99-999-999-9999"
                                                                maskChar=" "
                                                            />
                                                        </div> */}

                                                            {/* <div className="col-md-6 pd-l-15 pd-r-15 mg-b-30">
                                                    <FormControl fullWidth className="borderInput textfield">
                                                        <InputLabel id="demo-simple-select-label">Desired Price Range</InputLabel>
                                                        <Select
                                                        labelId="demo-simple-select-label"
                                                        id="Desired Price Range"
                                                        label="Desired Price Range"
                                                        onChange={this.handleChangePriceRange}
                                                        >
                                                        {this.state.priceRangeArray.map((key, i) => (
                                                        <MenuItem key={key.label} value={key.value}>
                                                            <ListItemText primary={key.label} />
                                                        </MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>
                                                    </div> */}
                                                            {/* <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                                        {this.state.bedroomArray.length > 1 ? (

                                                    <FormControl fullWidth className="borderInput textfield">
                                                        <InputLabel id="demo-simple-select-label">Bedrooms</InputLabel>
                                                        <Select
                                                        labelId="demo-simple-select-label"
                                                        id="Desired Price Range"
                                                        label="Desired Price Range"
                                                        onChange={this.handleChangebedroom}
                                                        >
                                                        {this.state.bedroomArray.map((key, i) => (
                                                        <MenuItem key={key.label} value={key.value}>
                                                            <ListItemText primary={key.label} />
                                                        </MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>
                                                    ) : (
                                                        <TextField
                                                            id="Desired Price Range"
                                                            label=""
                                                            type="select"
                                                            onChange={this.handleChangebedroom}
                                                            variant="outlined"
                                                            name="notes"
                                                            value={this.state.bedroomArray[0] ? (JSON.stringify(this.state.bedroomArray[0].label)).replace(/["']/g, "") : ""}
                                                            disabled
                                                            fullWidth
                                                            //onChange={this.changeHandler}
                                                            className="borderInput textfield"
                                                        />
                                                    )
                                                    }
                                                    </div> */}
                                                            <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                                            <div className="countryphone">
                                                                <TextField
                                                                    id="Comment"
                                                                    label="Comments"
                                                                    multiline
                                                                    rows={1}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    name="notes"
                                                                    value={this.state.notes}
                                                                    onChange={this.changeHandler}
                                                                    className="borderInput textfield"
                                                                />
                                                                </div>
                                                            </div>

                                                            {/* {this.state.errorCount >= 3 &&
                                                    <div id="recaptch-block" className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                                    <ReCAPTCHA
                                                    // theme="dark" // light
                                                    // onErrored:""
                                                    // badge="bottomright" //bottomleft inline
                                                    // size="normal" //"compact"
                                                    ref={recaptchaRef}
                                                    onChange={this.onChangeRecaptcha}
                                                    onExpired={() => this.onErrorRecaptcha}
                                                    onErrored={() => this.onErrorRecaptcha}
                                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                                    />
                                                    {
                                                            this.state.errors.recaptcha !== "" ?
                                                            <p className="error-message">{this.state.errors.recaptcha}</p>
                                                            : ""
                                                    }
                                                    </div>
                                                    } */}
                                                            <div className="col-md-12 pd-l-15 pd-r-15 mg-b-0 ">
                                                                <button onClick={this.saveOrUpdateApplicants}
                                                                    disabled={this.state.loader ? true : false}

                                                                    className="">
                                                                    Confirm
                                                                    {this.state.loader ?
                                                                        <>
                                                                            {/* <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                                size="sm"
                                                                            />
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                                size="sm"
                                                                            />
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                                size="sm"
                                                                            /> */}

                                                                            <SpinnerLoader />
                                                                        </>
                                                                        : ""
                                                                    }
                                                                </button>
                                                                <a href={"/virtual-tour-confirmation"}> <button hidden className="w-100" id="virtual-tour-button">Confirm</button></a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="col-md-6">
                                                    <div className="address_sec">
                                                        <h3>Address</h3>
                                                        <div className="d-flex address_icons">
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('building_icon.png')} alt="" />
                                                            <p>Kini Beach Haus / Las Olas, 1007 SE 2nd Ct, Fort Lauderdale, Florida, United States</p>
                                                        </div>
                                                        <div className="d-flex address_icons">
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('building_icon.png')} alt="" />
                                                            <p>Merino / Scottsdale, 8550 E McDowell Rd, Scottsdale, Arizona, United States</p>
                                                        </div>
                                                        <div className="d-flex address_icons">
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('building_icon.png')} alt="" />
                                                            <p>Highgrove / Austin, 5900 Cameron Rd, Austin, Texas, United States</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>

                }
                {/* Footer */}
                <CommonFooter propertySlug={this.state.property_slug} />
            </main >
        );
    }
}
export default Contact