////////////////////////////////////////////////////////////
//     							                          //
//  Program: OnKeyPressEvent.jsx                          //
//  Application: Common Components                        //
//  Option: For to prevent specific key value             //
//  Developer: CV  						                  //
//  Date: 2021-04-13                                      //
////////////////////////////////////////////////////////////

import { Component } from "react";

class OnKeyPressEvent extends Component {
  setTheme = () => {
    var url = window.location.href;
    var kini = url.includes("kini");
    var theme = kini ? "kini" : "oh";

    return theme == "kini"
      ? "./assets/scss/kini_html.scss"
      : "./assets/scss/oh_html.scss";
  };

  phoneValidation = (event) => {
    // 0-9, space, +, -, ()
    let charCodeArray = [32, 40, 41, 43, 45];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  mobileValidation = (event) => {
    // 0-9, +
    let charCodeArray = [43];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  floatValidation = (event) => {
    // 0-9, dot
    let charCodeArray = [46];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  numberValidation = (event) => {
    // 0-9
    let charCodeArray = [];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  numSpaceValidation = (event) => {
    // 0-9, space
    let charCodeArray = [32];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  numSpaceDashValidation = (event) => {
    // 0-9, space, dash
    let charCodeArray = [32, 45];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  alphaValidation = (event) => {
    // A-Z
    let charCodeArray = [];
    if (
      (event.charCode >= 97 && event.charCode <= 122) ||
      (event.charCode >= 65 && event.charCode <= 90) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  alphaSpaceValidation = (event) => {
    // A-Z, space
    let charCodeArray = [32];
    if (
      (event.charCode >= 97 && event.charCode <= 122) ||
      (event.charCode >= 65 && event.charCode <= 90) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  alphaNumValidation = (event) => {
    // 0-9, A-Z
    let charCodeArray = [];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      (event.charCode >= 97 && event.charCode <= 122) ||
      (event.charCode >= 65 && event.charCode <= 90) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  alphaNumSpaceValidation = (event) => {
    // 0-9, A-Z, space
    let charCodeArray = [32];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      (event.charCode >= 97 && event.charCode <= 122) ||
      (event.charCode >= 65 && event.charCode <= 90) ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  excludeSpaceValidation = (event) => {
    // exclude space
    let charCodeArray = [32];
    if (charCodeArray.indexOf(event.charCode) > -1) {
      event.preventDefault();
    } else {
      return true;
    }
  };

  timeValidation = (event) => {
    let charCodeArray = [];
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode == 58 ||
      charCodeArray.indexOf(event.charCode) > -1
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  //for thousand seprattor
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  //for thousand seprattor having decimal more than 2 (i.e. 4220.1200)
  numberWithCommasForDecimal(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  // For rounded point value
  customeToFixed(num, precision = 2) {
    if (num === "" || num === null || num === "NaN") {
      num = 0;
    }
    return parseFloat(
      +(Math.round(+(num + "e" + precision)) + "e" + -precision)
    ).toFixed(precision);
  }

  // For without rounded point value
  customeToFixedDown(num, precision = 2) {
    if (num === "" || num === null || num === "NaN") {
      num = 0;
    }
    return Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision);
  }

  // To check users access permission
  checkPermission(route) {
    let permissions = localStorage.getItem("permissions")
      ? localStorage.getItem("permissions")
      : [];
    if (permissions.length > 0) {
      let permissions1 = permissions.split(",");
      //for matching permission slug (if not exist,it will return -1)
      if (permissions1.indexOf(route) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  dispNumber(n, d, prefix, suffix) {
    // convert n to a display number with d decimals
    var s = Number(n);
    if (s >= 0) {
      s = this.inpNumber(n, d) + "&nbsp;";
    } else {
      n = Math.abs(n);
      s = '<span class="negnum">(' + this.inpNumber(n, d) + ")</span>";
    }
    return (prefix ? prefix : "") + s + (suffix ? suffix : "");
  }

  inpNumber(n, d) {
    if (Math.floor(n) != n) {
      n = Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
    }
    var s = n.toString();
    // insert commas
    var p = s.indexOf(".");
    if (p < 0) {
      p = s.length;
      if (d > 0) {
        s = s + ".";
        for (var i = 0; i < d; i++) s = s + "0";
      }
    } else if (s.length - p - 1 < d) {
      for (i = s.length - p - 1; i < d; i++) s = s + "0";
    }
    for (i = p - 3; i > 0; i -= 3) s = s.substr(0, i) + "," + s.substr(i);
    return s;
  }

  generateSlug(length = 12) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  validateEmail(email) {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(email) === false) {
      return false;
    } else {
      return true;
    }
  }

  validatePassword(pw) {
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{6,}/;
    if(!uppercaseRegExp.test(pw)){
      return "Your password must contain at least one upper case characters(A-Z).";
    }else if (!lowercaseRegExp.test(pw)){
      return "Your password must contain at least one lower case character(a-z).";
    }else if (!digitsRegExp.test(pw)){
      return "Your password must contain at least one number (0-9).";
    }else if (!specialCharRegExp.test(pw)){
      return "Your password must contain at least one symbol (#?!@$%^&*-).";
    }else if (!minLengthRegExp.test(pw)){
      return "Your password must have at least 6 characters long.";
    } else {
      return "";
    }
  }

  getBrandSlug (){
      if(["dev.kinibeachhaus.com","https://dev.kinibeachhaus.com","stg.kinibeachhaus.com","https://stg.kinibeachhaus.com","www.kinibeachhaus.com","https://www.kinibeachhaus.com","localhost:3000"].includes(global.host)){
        return "kini-beach-haus";
      } else if(["www.thefredrick-apartments.com"].includes(global.host)){
        // return "the-fredrick"; //dev
        // return "the-fredrick"; //stg
        return "the-fredrick"; // prd
      }else if(["www.merino-apartments.com","https://www.merino-apartments.com","merino-apartments.com"].includes(global.host)){
        // return "merino"; //dev
         //return "merino"; //stg
        return "merino"; // prd
      }else if(["www.acadiancrossing-apartments.com","https://www.acadiancrossing-apartments.com","acadiancrossing-apartments.com"].includes(global.host)){
        // return "merino"; //dev
         //return "merino"; //stg
        return "acadian-crossing-lafayette"; // prd
      } else{
        return "kini-beach-haus";
      }
  }

  getPropertySlug() {
    if (["stg.kinibeachhaus-lasolas.com", "https://stg.kinibeachhaus-lasolas.com", "https://www.kinibeachhaus-lasolas.com", "www.kinibeachhaus-lasolas.com", "localhost:3002", "fort-lauderdale-fl"].includes(global.host)) {
      // return "kini-beach-haus-las-olas-2"; // Dev
      // return "kini-beach-haus-las-olas"; // Stg
      return "kini-beach-haus-las-olas"; // Prd
    } else if(["www.thefredrick-tempe.com","tempe-az"].includes(global.host)){
      // return "the-fredrick-tempe"; // dev
       //return "the-fredrick-tempe"; // stg
      return "the-fredrick-tempe"; //prd
    }
    else if (["www.merino-scottsdale.com", "https://www.merino-scottsdale.com", "https://merino-scottsdale.com", "merino-scottsdale.com", "scottsdale-az"].includes(global.host)) {
      // return "merino-scottsdale-2"; // dev
       //return "merino-scottsdale-2"; // stg
      return "merino-scottsdale"; //prd
    }
    else if (["https://www.orionhaus-scottsdale.com", "https://orionhaus-scottsdale.com", "www.orionhaus-scottsdale.com", "orionhaus-scottsdale.com", "scottsdale-az"].includes(global.host)) {
      // return "merino-scottsdale-2"; // dev
       //return "merino-scottsdale-2"; // stg
      return "merino-scottsdale"; //prd
    }
    else if (["https://www.acadiancrossing-lafayette.com", "https://acadiancrossing-lafayette.com", "www.acadiancrossing-lafayette.com", "acadiancrossing-lafayette.com", "Lafayette"].includes(global.host)) {
      // return "merino-scottsdale-2"; // dev
       //return "merino-scottsdale-2"; // stg
      return "acadian-crossing-lafayette"; //prd
    }
    else {
      // return "kini-beach-haus-las-olas-2"; // Dev
       //return "kini-beach-haus-las-olas"; // Stg
      return "kini-beach-haus-las-olas"; // Prd
    }
  }

  getPropertySlugByCity(city = "") {
    if (["fort-lauderdale-fl"].includes(city)) {
      // return "kini-beach-haus-las-olas-2"; // Dev
       //return "kini-beach-haus-las-olas"; // Stg
      return "kini-beach-haus-las-olas"; // Prd
    } else if(["tempe-az"].includes(city)){
      // return "the-fredrick-tempe"; // dev
      // return "the-fredrick-tempe"; // stg
      return "the-fredrick-tempe"; //prd
    }
    else if (["scottsdale-az"].includes(city)) {
      // return "merino-scottsdale-2"; // dev
       //return "merino-scottsdale-2"; // stg
      return "merino-scottsdale"; //prd
    }
    else if (["merino-scottsdale-az"].includes(city)) {
      // return "merino-scottsdale-2"; // dev
     // return "merino-scottsdale-2"; // stg
      return "merino-scottsdale"; //prd
    }
    else if (["Lafayette"].includes(city)) {
      // return "merino-scottsdale-2"; // dev
     // return "merino-scottsdale-2"; // stg
      return "acadian-crossing-lafayette"; //prd
    }
    else if (["Austin"].includes(city)) {
      // return "merino-scottsdale-2"; // dev
     // return "merino-scottsdale-2"; // stg
      return "highgrove-austin"; //prd
    }
    else {
      // return "kini-beach-haus-las-olas-2"; // Dev
      //return "kini-beach-haus-las-olas"; // Stg
      return "kini-beach-haus-las-olas"; // Prd
    }
  }
  getS3ImageUrl(imageName = "", imagePath = "website/images") {
    return process.env.REACT_APP_S3_BUCKET_URL + "/" + imagePath + "/" + imageName;
  }

}

export default OnKeyPressEvent;
